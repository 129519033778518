'use client';

import packageJson from '@/../package.json';
import webViewService from '@/lib/webViewService';
import { isClientSide } from '@vuddy/utils';
import { env } from 'next-runtime-env';
import { useContext } from 'react';
import { HistoryIndexContext } from '../providers/HistoryIndexProvider';
import * as styles from './version.css';

export const Version = () => {
  const historyIndex = useContext(HistoryIndexContext);

  // fixme 루트 layout 이 최초로 그려질때 notFound() 를 호출하면 next env 주입이 되지 않아서 임시 처리

  if (
    (isClientSide && !window.__ENV) ||
    ['production', 'internal-prod'].includes(env('NEXT_PUBLIC_PHASE'))
  ) {
    return null;
  }

  const infos = [
    `${env('NEXT_PUBLIC_PHASE').toUpperCase()}: ${packageJson.version}`,
    historyIndex,
    webViewService.getDevicePlatform(isClientSide ? navigator.userAgent : ''),
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.version}>{infos.join('/')}</div>
    </div>
  );
};
